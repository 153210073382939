.footer {
    position: fixed;
    background: #fafafa;
    color: #888;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: calc( constant(safe-area-inset-bottom) + 10px); /* 兼容 iOS < 11.2 */
    padding-bottom: calc( env(safe-area-inset-bottom) + 10px); /* 兼容 iOS >= 11.2 */
}
.beian {
    cursor: pointer;
}