.header {
    display: flex;
    margin: 20px 0;
    .item {
        height: 32px;
        padding: 4px 16px;
        font-size: 14px;
        border-radius: 32px;
        margin-right: 20px;
        height: 32px;
        padding: 4px 16px;
        font-size: 14px;
        border-radius: 32px;
        line-height: 1.5715;
        position: relative;
        display: inline-block;
        font-weight: 400;
        white-space: nowrap;
        text-align: center;
        background-image: none;
        border: 1px solid transparent;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        touch-action: manipulation;
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-color: #d9d9d9;
    }
    .item-focus {
        border-color: #40a9ff;
        color: #40a9ff;
    }
}

.scroll-view {
    overflow-y: auto !important;
}
.courses {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: space-between;
    min-height: 450px;
    .card {
        margin-bottom: 20px;
    }
}
.button {
    margin-right: 20px;
}